import * as Turbo from "@hotwired/turbo";

// Turboを無効化して必要なelementにdata-turbo="true"を追加する
Turbo.session.drive = false;

// 共通初期化関数
const initialize = () => {
  menu();
};

// メイン関数
const main = () => {
  document.addEventListener("turbo:load", initialize);
  document.addEventListener("turbo:render", initialize);
};
main();

/**
 * menuの開閉
 * @returns
 */
function menu() {
  const btn = document.querySelector('#js-menu');
  const overlay = document.querySelector('.bg-overlay');

  if (btn != null) {
      btn.addEventListener('click', (e) => {
          e.preventDefault();
          btn.classList.toggle('is-active');
          btn.parentElement.classList.toggle('show-menu');
      });
  }

  if (overlay != null) {
      overlay.addEventListener('click', () => {
          btn.classList.remove('is-active');
          btn.parentElement.classList.remove('show-menu');
      });
  }
}